<template>
    <DetailItemEditCard v-bind="$attrs" v-on="$listeners" :item-name="taskName" :promises.sync="promises"
        confirmation-message="Taken zijn succesvol gegeneerd"
        :customResponseDataToConfirmMessageHandler="customResponseDataToConfirmMessageHandler" title="Genereer taken"
        update-store-action="tasksModule/generateMorePlannedTasks"
        :update-store-action-payload="{ taskId: taskId, projectId: projectId, clientId: clientId, weeksInAdvance: weeksInAdvance }"
        :detailViewRouteLocation="{name: routeNames.TASK_DETAIL, params: {projectId: projectId, clientId: clientId, taskId:taskId}}"
        :successRouteLocation="{ name: routeNames.TASK_DETAIL, params: { projectId: projectId, clientId: clientId, taskId: taskId }}"
        >
        <template v-slot:form-fields>
            Momenteel zijn er voor {{ functionalId }} taken gegenereerd tot en met {{ applyDateFormat(latestPlannedTask?.scheduleTo) }}.
            <v-row>
                <v-col cols="10" md="3">
                    <v-text-field v-model="weeksInAdvance" type="number" :min="1" :max="24" :rules="weeksInAdvanceRules" label="Genereer extra weken:" />
                </v-col>
            </v-row>
        </template>
        <template v-slot:save-button-content>
            Genereer
        </template>
    </DetailItemEditCard>
  </template>
  
  <script>
  import DetailItemEditCard from "@/components/shared/DetailItemEditCard.vue"
  import routeNames from "@/router/RouteNames"
  import { getRequiredRules, getBiggerThanZeroRules, getMaximumNumberRules } from "@/shared/utils/inputRulesUtils.js"
  import { applyDateFormat } from "@/shared/utils/dateUtils.js"
  
  export default {
    name: "TaskGenerate",
    props: {
      projectId: {
        type: String,
        required: true
      },
      clientId: {
        type: String,
        required: true
      },
      taskId: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        routeNames,
        taskName: null,
        functionalId: null,
        latestPlannedTask: null,
        weeksInAdvance: 1,
        promises: [
            this.$store.dispatch('tasksModule/fetchTaskBasic', { clientId: this.clientId, projectId: this.projectId, taskId: this.taskId })
                .then((result) => { this.taskName = result.taskName; this.functionalId = result.functionalId }),
            this.$store.dispatch('tasksModule/fetchLatestPlannedTask', this.taskId)
                .then((result) => this.latestPlannedTask = result)
        ],
        weeksInAdvanceRules: [
          ...getRequiredRules('Aantal weken moet ingevuld worden'),
          ...getBiggerThanZeroRules('Aantal weken moet groter dan 0 zijn'),
          ...getMaximumNumberRules('Aantal weken moet kleiner dan 24 zijn', 24)
        ]
      }
    },
    components: {
      DetailItemEditCard
    },
    methods: {
      getRequiredRules,
      getBiggerThanZeroRules,
      getMaximumNumberRules,
      applyDateFormat,
      // Used for dynamic succes message, assumes endpoint just returns an integer
      customResponseDataToConfirmMessageHandler(responseData) {
        return `Er zijn ${responseData} taken gegenereerd`
      }
    },
  }
  </script>